import {
  faLink,
  faUnlink,
  faInfoCircle,
} from "@fortawesome/pro-light-svg-icons"
import {} from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { navigate } from "gatsby"
import React, { FunctionComponent, useCallback, useState } from "react"
import { useStyle } from "../hooks/use-style"
import { getCachedSecret, useUser } from "../hooks/use-user"
import { useNode } from "../hooks/use-node"
import { darkTheme, lightTheme } from "../services/theme"
import { ChannelLinkData, ChannelLinkReview } from "../types"
import Avatar from "./layout/avatar"
import { showToast } from "./status-notification"

type ChannelProps = {
  channel: ChannelLinkData
}

const Channel: FunctionComponent<ChannelProps> = ({ channel }) => {
  const pos = channel.alias.indexOf("#")
  const website =
    pos == -1
      ? channel.alias
      : channel.alias.substr(pos + 1, channel.alias.length - pos - 1)
  const { rpc } = useNode()

  return (
    <div className="main">
      <div>
        <div
          className="header"
          onClick={() => {
            navigate("/channel/" + channel.id)
          }}
        >
          <Avatar
            image={channel.image ?? undefined}
            label={channel.title}
            size={30}
          />
          <div className="title">
            <div className="">
              {channel.title} on {website}
            </div>
          </div>
        </div>
        <div className="description">
          {channel.description}
          {channel.home && channel.home.length && (
            <a href={"https://" + channel.home} target="_blank">
              home
            </a>
          )}
        </div>
      </div>

      <style jsx>{`
        .header {
          display: flex;
          width: fit-content;
          background-color: rgba(105, 105, 105, 0.05);
          padding: 1px 5px;
          border-radius: 5px;
        }
        .header:hover {
          background-color: rgba(105, 105, 105, 0.2);
          cursor: pointer;
        }

        .title {
          margin-top: auto;
          margin-bottom: auto;
          margin-left: 10px;
        }
        .main {
          font-family: "Roboto";
          margin-bottom: 5px;
        }
        .description {
          font-size: 14px;
          padding: 5px;
        }
        .interactable {
          position: relative;
          z-index: 1;
        }
        .label {
          min-width: 0;
        }
      `}</style>
    </div>
  )
}

type Props = {
  item: ChannelLinkReview
  remove: (id: string) => void
}

const LinkModeration: FunctionComponent<Props> = ({ item, remove }) => {
  const { user } = useUser()

  const css = useStyle(theme => ({
    infoColor:
      theme === "light"
        ? lightTheme.color.text.secondary
        : darkTheme.color.text.secondary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
  }))

  const link = useCallback(
    (pro: boolean) => {
      const ids = item.id.split(",")
      let payload = {
        alias: ids[0],
        superchannel: ids[1],
        pro: pro,
      }

      rpc(
        "link_channel",
        JSON.stringify(payload),
        user,
        getCachedSecret(),
        function (res) {
          let message
          if (res.status == "ok") {
            message = "Moderation sent. Thank you!"
            //if (user) updateBalance(-0.01) // todo: update
          } else message = "Edit failed"

          showToast(faInfoCircle, "Smartlike network", message)
        }
      )
      remove(item.id)
    },
    [item, user, remove]
  )

  const pro = item.pro >= item.contra ? 1 : -1

  return (
    <article>
      <Channel channel={item.channel} />
      <Channel channel={item.superChannel} />
      <div className="footer">
        <div className="stats">$ {item.fee}</div>
        <div className="controls">
          <div style={{ display: "flex", float: "right" }}>
            <div
              className={
                item.mod == 0
                  ? pro == -1
                    ? "yellow choice"
                    : "choice"
                  : item.mod == -1
                  ? "green choice"
                  : "choice"
              }
              onClick={() => link(false)}
            >
              <div className="votes">{item.contra}</div>
              <div className="btn">
                <FontAwesomeIcon icon={faUnlink} size="lg" />
              </div>
            </div>
            <div
              className={
                item.mod == 0
                  ? pro == 1
                    ? "yellow choice"
                    : "choice"
                  : item.mod == 1
                  ? "green choice"
                  : "choice"
              }
              onClick={() => link(true)}
            >
              <div className="votes">{item.pro}</div>
              <div className="btn">
                <FontAwesomeIcon icon={faLink} size="lg" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .green {
          background-color: lightgreen;
        }
        .yellow {
          background-color: yellow;
        }
        .red {
          background-color: lightred;
        }
        .choice {
          display: table;
          margin-left: 15px;
          margin-right: 5px;
          border-radius: 5px;
        }
        .suggested {
        }
        .moderated {
        }
        .votes {
          display: table-cell;
          padding-left: 5px;
          padding-right: 5px;
        }
        .footer {
          display: table;
          width: 100%;
        }
        .pro {
          background-color: lightgreen;
          padding: 2px;
          padding-left: 10px;
          padding-right: 10px;
        }
        .contra {
          background-color: lightred;
          padding: 2px;
          padding-left: 10px;
          padding-right: 10px;
        }
        .stats {
          font-family: "Roboto";
          font-size: 14px;
          display: table-cell;
          vertical-align: middle;
        }
        .controls {
          display: table-cell;
          text-align: right;
        }
        .btn {
          padding: 3px;
          font-size: 18px;
          display: table-cell;
        }
        .btn:hover {
          cursor: pointer;
        }
      `}</style>
    </article>
  )
}

export default LinkModeration
