import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react"
import { Helmet as Head } from "react-helmet"
import { useFilters } from "../hooks/use-filters"
import SortFilter from "../components/layout/sort-filter"
import CommentModeration from "../components/comment/comment-moderation"
import { ChannelLinkData, ChannelLinkReview, Comment } from "../types"
import { useLazyLoading } from "../hooks/use-lazy-loading"
import { lightTheme, darkTheme } from "../services/theme"
import { useStyle } from "../hooks/use-style"
import ResponsiveLayout from "../components/layout/responsive-layout"
import LinkModeration from "../components/link-moderation"

const SuperChannelsPage: FunctionComponent = () => {
  const { filters } = useFilters()
  const [items, setItems] = useState<ChannelLinkReview[]>([])

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.primary
        : darkTheme.color.background.secondary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    infoColor:
      theme === "light"
        ? lightTheme.color.text.secondary
        : darkTheme.color.text.secondary,
    tagColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
  }))

  const fetchPage = useCallback(
    async (offset: number) => {
      let url =
        /*process.env.API_URL +*/ "/api/v2/superchannels?count=40&offset=" +
        20 * offset +
        "&mod=" +
        filters.mod +
        "&sort=" +
        filters.sort +
        "&age=" +
        filters.age
      if (filters.donation_country != "any")
        url += "&country=" + filters.donation_country
      if (filters.donation_currency != "any")
        url += "&currency=" + filters.donation_currency

      console.log(url)

      //if (filters.liked == false) {
      const response = await Promise.resolve(
        fetch(url)
          .then(res => res.json())
          .catch(error => {
            console.log("json parsing error occured ", error)
            return null
          })
      )
      if (response) {
        let items: ChannelLinkReview[] = []
        for (var i = 0; i < response.length; i++) {
          let v = JSON.parse(response[i])
          const meta = JSON.parse(v.meta)
          let ch: ChannelLinkReview = {
            id: v.id,
            pro: v.pro,
            contra: v.contra,
            fee: v.fee,
            mod: v.mod,
            channel: JSON.parse(meta.channel_a),
            superChannel: JSON.parse(meta.channel_b),
          }
          items.push(ch)
          console.log(ch)
        }
        return items
      } else {
        console.log(response)
        return []
      }
      //} else return []
    },
    [filters]
  )
  /*
  const hideComment = useCallback(
    (commentId: string) => {
      //console.log("hideComment " + commentId)
      setItems(comments =>
        comments.filter(comment => comment.id !== commentId)
      )
    },
    [comments, setComments]
  )
*/
  const add = useCallback(
    (newItems: ChannelLinkReview[], offset: number) => {
      if (newItems) {
        //console.log(feedId + " " + offset + " " + newItems)
        if (offset == 0 && items.length > 0) {
          console.log("clearing items")
          setItems(newItems)
        } else setItems(items => items.concat(newItems))
      }
      // TODO: trigger single rererender
    },
    [items]
  )

  const remove = useCallback(
    (id: string) => {
      setItems(items => items.filter(item => item.id !== id))
    },
    [items]
  )

  useEffect(() => {
    //console.log("reloading")
    resetLoader()
  }, [filters])

  const { isLoading, isContentAvailable, resetLoader } =
    useLazyLoading<ChannelLinkReview>(items, { fetchPage, add })

  return (
    <>
      <Head>
        <title>Smartlike</title>
        <meta name="description" content="Smartlike p2p donations" />
        <meta property="og:title" content="Smartlike" />
        <meta property="og:description" content="Smartlike p2p donations" />
      </Head>

      <ResponsiveLayout>
        {{
          content: (
            <ul>
              {items.map(item => (
                <li key={item.id} className="element">
                  <LinkModeration item={item} remove={remove} />
                </li>
              ))}
            </ul>
          ),
          sidebar: (
            <>
              <SortFilter />
            </>
          ),
        }}
      </ResponsiveLayout>
      <style jsx>{`
        .element {
          padding: 10px;
          background-color: ${css.backgroundColor};
          width: 100%;
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;
        }
        li:not(:first-child) {
          margin-top: 1em;
        }
        li:not(:last-child) {
          margin-bottom: 1em;
        }
      `}</style>
    </>
  )
}
export default SuperChannelsPage
